import React from "react"
import AuthSection from "./../components/AuthSection"
import { useRouter } from '../util/router'
import Meta from "../components/Meta";
import NavbarCustom from "../components/NavbarCustom";

function AuthPage(props) {
  const router = useRouter();

  return (
      <>
        <Meta />
        <NavbarCustom
            bg="white"
            variant="light"
            expand="md"
            logo="https://uploads.divjoy.com/logo.svg"
        />
        <AuthSection
            bg="white"
            textColor="dark"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            inputSize="lg"
            type={router.query.type}
            providers={["google", "facebook", "twitter"]}
            afterAuthPath={router.query.next || "/dashboard"}
        />
      </>
  )
}

export default AuthPage

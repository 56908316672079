import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { LinkContainer } from "react-router-bootstrap";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";
import {usePosts} from "../util/db";

function ContentCardsSection(props) {
  const { data } = usePosts()
  console.log('posts', data)
  return (
      <Section
          bg={props.bg}
          textColor={props.textColor}
          size={props.size}
          bgImage={props.bgImage}
          bgImageOpacity={props.bgImageOpacity}
      >
          <Container>
              <SectionHeader
                  title={props.title}
                  subtitle={props.subtitle}
                  size={2}
                  spaced={true}
                  className="text-center"
              />
              <Row className="justify-content-center">
                  {data && data.map((item, index) => (
                      <Col xs={12} md={6} lg={3} className="py-3" key={index}>
                          <LinkContainer to={"/posts/" + item.id}>
                              <Card as="a" text="dark" className="text-decoration-none">
                                  <AspectRatio ratio={1 / 0.75}>
                                      <Card.Img src={item.image} alt={item.title} variant="top" />
                                  </AspectRatio>
                                  <Card.Body>
                                      <Card.Title>{item.title}</Card.Title>
                                  </Card.Body>
                              </Card>
                          </LinkContainer>
                      </Col>
                  ))}
              </Row>
          </Container>
      </Section>
      )



}

export default ContentCardsSection;

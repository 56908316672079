import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Uploader from './Uploader'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { createPost } from '../util/db'
import Image from 'react-bootstrap/Image'
import { useRouter } from '../util/router'
import {requireAuth, useAuth} from "../util/auth";

const AddImage = () => {
  const auth = useAuth();
  const router = useRouter()
  const [ title, setTitle ] = useState('')
  const [ imageUrl, setImageUrl ] = useState('')
  const handleChange = fn => e => {
    fn(e.target.value)
  }

  const processUpload = url => Promise.resolve(setImageUrl(url))

  const handleSubmit = e => {
    e.preventDefault()
    if (auth.user && imageUrl) {
      createPost({
        title,
        image: imageUrl,
        owner: auth.user.identities[0].user_id
      }).then(docRef => {
        router.push('/')
      })
    }
  }
  return (
    <Container className="p-3">
      <Uploader processUpload={processUpload} />
      {imageUrl && <Image src={imageUrl} fluid />}
      <Form className="mt-3" onSubmit={handleSubmit}>
        <Form.Group controlId="title">
          <Form.Label>Caption</Form.Label>
          <Form.Control type="text" onChange={handleChange(setTitle)} value={title} />
        </Form.Group>
        <Button variant="primary" type="submit">
          Post
        </Button>
      </Form>
    </Container>
  )
}

export default requireAuth(AddImage)

import React from "react";
import Meta from "./../components/Meta";
import NavbarCustom from "./../components/NavbarCustom";
import ContentCardsSection from "./../components/ContentCardsSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <NavbarCustom
        bg="white"
        variant="light"
        expand="md"
        logo=""
      />
      <ContentCardsSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Latest posts"
        subtitle=""
      />
    </>
  );
}

export default IndexPage;

import React from "react";
import Meta from "./../components/Meta";
import NavbarCustom from "./../components/NavbarCustom";
import AddImage from "../components/AddImage";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <NavbarCustom
        bg="white"
        variant="light"
        expand="md"
        logo="https://uploads.divjoy.com/logo.svg"
      />
      <AddImage />
    </>
  );
}

export default IndexPage;

import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import './Uploader.scss'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

const apiEndPoint = 'https://api.Cloudinary.com/v1_1/nubz/image/upload'

const Uploader = props => {
  const { processUpload, label } = props
  const [ showLoader, setShowLoader ] = useState(false)
  const handleClose = e => {
    setShowLoader(false)
  }
  const handleImageUpload = () => {
    const { files } = document.querySelector('input[type="file"]')
    if (files.length) {
      setShowLoader(true)
    }
    const formData = new FormData()
    formData.append('file', files[0])
    formData.append('upload_preset', 'rruqs9oc')
    const options = {
      method: 'POST',
      body: formData,
    };

    return fetch(apiEndPoint, options)
      .then(res => res.json())
      .then(res =>
        processUpload(res.secure_url)
          .then(setShowLoader(false))
      )
      .catch(err => console.log(err));
  }
  return (
    <>
      <form>
        <div className="uploader-form-group">
          <label htmlFor="upload-image" className={"uploader-mask"}>
            <Button variant={"outline-primary"}>
              <i className="fa fa-upload" aria-hidden="true" />{' '}
              {props.label || "Upload image"}
            </Button>
            <input id="upload-image" data-testid="upload-file" type="file" onChange={handleImageUpload} />
          </label>
        </div>
      </form>
      <Modal
        show={showLoader}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Uploading new image</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{textAlign: "center"}}>
          <Spinner
            animation="border"
            size={"l"}
            variant={"success"}
            role="status"
          >
            <span className="sr-only" data-testid="spinner-text" >Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Uploader

import React from "react";
import "./../styles/global.scss";
import IndexPage from "./index";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import { AuthProvider } from "./../util/auth";
import AuthPage from "./auth";
import NewPostPage from "./new-post"
import { QueryClientProvider } from "./../util/db";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          <Switch>
            <Route exact path="/" component={IndexPage} />
            <Route exact path="/auth/:type" component={AuthPage} />
            <Route exact path="/new-post" component={NewPostPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
